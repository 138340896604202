import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("userData"));
const token = localStorage.getItem('token')
const role = localStorage.getItem('role')
const userId = localStorage.getItem('userId')
const tenantName = localStorage.getItem('tenantName')
const startWork = localStorage.getItem('startWork')
const openStartModal= localStorage.getItem('openStartModal')

const initialState = token
    ? { isLoggedIn: true, user, token, role, userId, tenantName,startWork,openStartModal }
    : { isLoggedIn: false, user: null, token: null, role: null, userId: null, tenantName: null, startWork: null, openStartModal:null };

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                token: payload.token,
                role: payload.role,
                userId: payload.userId,
                tenantName: payload.tenantName,
                startWork: payload.startWork,
                openStartModal:payload.openStartModal,

            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                token: null,
                role: null,
                userId: null,
                tenantName: null,
                startWork: null,
                openStartModal:null,

            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                user: null,
                token: null,
                role: null,
                userId: null,
                tenantName: null,
                startWork: null,
                openStartModal:null,
                
            };
        default:
            return state;
    }
}
