import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { Worker } from '@react-pdf-viewer/core';

const loading = (<div className="pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const ResetPassword = React.lazy(() => import('./views/pages/resetpassword'))
const ActivateAccount = React.lazy(() => import('./views/pages/activateaccount'))
const Upload = React.lazy(() => import('./views/pages/upload'))
const PublicRecord = React.lazy(() => import('./views/pages/publicRecord'))
const CreateRecord = React.lazy(() => import('./views/pages/createRecord'))

class App extends Component {
  render() {
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js">
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
              <Route exact path="/passwordreset/:token/:tenant" name="Reset Password" render={(props) => <ResetPassword {...props} />} />
              <Route exact path="/activateaccount/:token/:tenant" name="Activate Account" render={(props) => <ActivateAccount {...props} />} />
              <Route exact path="/upload/:token/:tenant" name="Upload" render={(props) => <Upload {...props} />} />
              <Route exact path="/public/:token/:tenant" name="Public Record" render={(props) => <PublicRecord {...props} />} />
              <Route exact path="/partner/:partnerId/:tenant" name="Create Record" render={(props) => <CreateRecord {...props} />} />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </Worker>
    )
  }
}

export default App
